import React from "react";

const Thankyou = ({ entityName }) => {

  return (
    <div className="text-center mt-5">
      <img src={require('./../assets/images/tick-circle.png')} alt="Thankyou" height="60" className='mb-3' />
      <h5><strong>Thank you!</strong></h5>
      <p>Your form is submitted</p>
    </div>
  );
};

export default Thankyou;
