import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const EmployeeInfo = ({ nextStep, prevStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});

  const [selectedMoreThan80PerOwnership, setSelectedMoreThan80PerOwnership] = useState('');
  const [selectedHourlyWorker, setSelectedHourlyWorker] = useState('');
  const [selectedProvideHealthcare, setSelectedProvideHealthcare] = useState('');
  const [selectedMoreThan50PerBusiness, setSelectedMoreThan50PerBusiness] = useState('');

  const [inputs, setInputs] = useState({});
  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const [empNamePlaceholder, setEmpNamePlaceholder] = useState('Enter employee names here....');
  const [isEmpNameRequired, setIsEmpNameRequired] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const newData={...inputs}

    if(name==="MoreThan80PerOwnership"){
      setSelectedMoreThan80PerOwnership(event.target.value);
    }
    if(name==="MaintainHourlyWorkerRecords"){
      setSelectedHourlyWorker(event.target.value);
    }
    if(name==="ProvideHealthcare"){
      setSelectedProvideHealthcare(event.target.value);
    }
    if(name==="EmployeesOwnMoreThan50PerBusiness"){
      setSelectedMoreThan50PerBusiness(event.target.value);
      if(value === 'Yes') {
        console.log('Y');
        setEmpNamePlaceholder('Enter employee names here....*');
        setIsEmpNameRequired(true);
      } else if(value === 'No') {
        console.log('N');
        setEmpNamePlaceholder('Enter employee names here....');
        setIsEmpNameRequired(false);
      }
    }
    newData[name]=value;
    setInputs(newData);
  }

  const handleDelete = (event) => {
    console.log('handleDelete', event.keyCode, event.charCode);
    var key = event.keyCode || event.charCode;
  
    if( key == 8 || key == 46 ){
      setTimeout(() => {
        console.log(event.target.value);
        if(event.target.value === ''){
          setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      });
    } else if (key === 190 || key === 110 || key === 189 || key === 69 || key === 187) {//if '.' period or '.' decimal point or '-' or 'e' or '+' respectively
      event.preventDefault();
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    console.log(token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        setInputs({
          MoreThan80PerOwnership:items.MoreThan80PerOwnership,

          FTE2019:items.FTE2019,
          PTE2019:items.PTE2019,
          FTE2020:items.FTE2020,
          PTE2020:items.PTE2020,
          FTE2021:items.FTE2021,
          PTE2021:items.PTE2021,

          MaintainHourlyWorkerRecords:items.MaintainHourlyWorkerRecords,
          ProvideHealthcare:items.ProvideHealthcare,
          EmployeesOwnMoreThan50PerBusiness:items.EmployeesOwnMoreThan50PerBusiness,

          EmployeeNames:items.EmployeeNames,
          NoOfEmployeesAsFamilyMembers:items.NoOfEmployeesAsFamilyMembers,
        });
        setGetFormdata(items);

        if(items.MoreThan80PerOwnership === 'Yes') {
          setSelectedMoreThan80PerOwnership('Yes');
        } else if(items.MoreThan80PerOwnership === 'No') {
          setSelectedMoreThan80PerOwnership('No');
        }
        
        if(items.MaintainHourlyWorkerRecords === 'Yes') {
          setSelectedHourlyWorker('Yes');
        } else if(items.MaintainHourlyWorkerRecords === 'No') {
          setSelectedHourlyWorker('No');
        }

        if(items.ProvideHealthcare === 'Yes') {
          setSelectedProvideHealthcare('Yes');
        } else if(items.ProvideHealthcare === 'No') {
          setSelectedProvideHealthcare('No');
        }

        if(items.EmployeesOwnMoreThan50PerBusiness === 'Yes') {
          setSelectedMoreThan50PerBusiness('Yes');
        } else if(items.EmployeesOwnMoreThan50PerBusiness === 'No') {
          setSelectedMoreThan50PerBusiness('No');
        }
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    console.log(inputs);
    var formdata = {token:token, entity_group:entityName, items:inputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        nextStep();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  };

  return (
    <div>
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Does your company or its partners have 80% or more ownership in any other businesses ? {mandatoryEntities.includes("MoreThan80PerOwnership") && <span className='txt-red'>*</span>}</Form.Label>
            <div className="d-flex">
              <Form.Check inline type="radio" name="MoreThan80PerOwnership" value={"Yes" || ""} onChange={handleChange} id="moreThan80PerOwnershipYes" label="Yes" checked={selectedMoreThan80PerOwnership === 'Yes'} />
              <Form.Check inline type="radio" name="MoreThan80PerOwnership" value={"No" || ""} onChange={handleChange} id="moreThan80PerOwnershipNo" label="No" checked={selectedMoreThan80PerOwnership === 'No'} />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Please provide the number of W2 employees below. Full time employees are those that work 130 hours/month or 390 hours/quarter.</Form.Label>
            <Table striped borderless>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Average number of Full-time W2 Employees</th>
                  <th>Average number of Part-time W2 Employees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2019</th>
                  <td><Form.Control name="FTE2019" placeholder={mandatoryEntities.includes("FTE2019") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.FTE2019 || getFormdata.FTE2019} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                  <td><Form.Control name="PTE2019" placeholder={mandatoryEntities.includes("PTE2019") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.PTE2019 || getFormdata.PTE2019} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                </tr>
                <tr>
                  <th>2020</th>
                  <td><Form.Control name="FTE2020" placeholder={mandatoryEntities.includes("FTE2020") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.FTE2020 || getFormdata.FTE2020} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                  <td><Form.Control name="PTE2020" placeholder={mandatoryEntities.includes("PTE2020") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.PTE2020 || getFormdata.PTE2020} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                </tr>
                <tr>
                  <th>2021</th>
                  <td><Form.Control name="FTE2021" placeholder={mandatoryEntities.includes("FTE2021") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.FTE2021 || getFormdata.FTE2021} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                  <td><Form.Control name="PTE2021" placeholder={mandatoryEntities.includes("PTE2021") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.PTE2021 || getFormdata.PTE2021} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0" /></td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Did you maintain hourly worker records for 2019 / 2020 / 2021 and are these available if requested? {mandatoryEntities.includes("MaintainHourlyWorkerRecords") && <span className='txt-red'>*</span>}</Form.Label>
            <div className="d-flex">
              <Form.Check inline type="radio" name="MaintainHourlyWorkerRecords" value={"Yes" || ""} onChange={handleChange} id="hourlyWorkerYes" label="Yes" checked={selectedHourlyWorker === 'Yes'} />
              <Form.Check inline type="radio" name="MaintainHourlyWorkerRecords" value={"No" || ""} onChange={handleChange} id="hourlyWorkerNo" label="No" checked={selectedHourlyWorker === 'No'} />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Do you provide healthcare for your employees? {mandatoryEntities.includes("ProvideHealthcare") && <span className='txt-red'>*</span>}</Form.Label>
            <div className="d-flex">
              <Form.Check inline type="radio" name="ProvideHealthcare" value={"Yes" || ""} onChange={handleChange} id="provideHealthcareYes" label="Yes" checked={selectedProvideHealthcare === 'Yes'} />
              <Form.Check inline type="radio" name="ProvideHealthcare" value={"No" || ""} onChange={handleChange} id="provideHealthcareNo" label="No" checked={selectedProvideHealthcare === 'No'} />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Do any of your employees own more than 50% of the business? {mandatoryEntities.includes("EmployeesOwnMoreThan50PerBusiness") && <span className='txt-red'>*</span>}</Form.Label>
            <div className="d-flex">
              <Form.Check inline type="radio" name="EmployeesOwnMoreThan50PerBusiness" value={"Yes" || ""} onChange={handleChange} id="moreThan50PerBusinessYes" label="Yes" checked={selectedMoreThan50PerBusiness === 'Yes'} />
              <Form.Check inline type="radio" name="EmployeesOwnMoreThan50PerBusiness" value={"No" || ""} onChange={handleChange} id="moreThan50PerBusinessNo" label="No" checked={selectedMoreThan50PerBusiness === 'No'} />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            {/* <Form.Control as="textarea" name="EmployeeNames" placeholder={mandatoryEntities.includes("EmployeeNames") ? 'Enter employee names here....*' : 'Enter employee names here....'} defaultValue={inputs.EmployeeNames || getFormdata.EmployeeNames} onChange={handleChange} onKeyDown={handleDelete} style={{ height: '100px' }} /> */}
            <Form.Control as="textarea" name="EmployeeNames" placeholder={empNamePlaceholder} defaultValue={inputs.EmployeeNames || getFormdata.EmployeeNames} onChange={handleChange} onKeyDown={handleDelete} required={isEmpNameRequired} style={{ height: '100px' }} />
          </Form.Group>
          <Row>
            <Col sm={5}>
              <Form.Group className="mb-4">
                <Form.Label className="mb-3">How many employees are family members? {mandatoryEntities.includes("NoOfEmployeesAsFamilyMembers") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="NoOfEmployeesAsFamilyMembers" placeholder={mandatoryEntities.includes("NoOfEmployeesAsFamilyMembers") ? 'Enter Number here *' : 'Enter Number here'} defaultValue={inputs.NoOfEmployeesAsFamilyMembers || getFormdata.NoOfEmployeesAsFamilyMembers} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0"/>
              </Form.Group>
            </Col>
          </Row>
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default EmployeeInfo;
