import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const Covid19Info = ({ nextStep, prevStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});

  const [inputs, setInputs] = useState({});
  const [delayReasonInputs, setDelayReasonInputs] = useState([]);
  const [quartersInputs, setQuartersInputs] = useState([]);

  const[showOther, setShowOther] = useState(false);
  const[isOtherRequired, setIsOtherRequired] = useState(false);
  const[isOtherDisabled, setIsOtherDisabled] = useState(true);

  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const [nextDisabled, setNextDisabled] = useState(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const newData={...inputs}

    
    if(name === 'ReducedServiceHoursReasons'){
      const delayReasonInputId = event.target.id;
      //setDelayReasonInputs(inputs.ReducedServiceHoursReasons);
      if (event.target.checked) {
        setDelayReasonInputs(values => ([...values, delayReasonInputId]));
        setDelayReasonInputs((stateDelayReasonInputs) => {
          console.log(stateDelayReasonInputs);
          newData['ReducedServiceHoursReasons']=stateDelayReasonInputs;
          return stateDelayReasonInputs;
        });

        if(delayReasonInputId.includes("Other")){//toggle Other textbox
          setShowOther(true);
          setIsOtherRequired(true);
          setIsOtherDisabled(false);
        }
      } else {
        setDelayReasonInputs(delayReasonInputs.filter(e => e !== delayReasonInputId));
        setDelayReasonInputs((stateDelayReasonInputs) => {
          console.log(stateDelayReasonInputs);
          newData['ReducedServiceHoursReasons']=stateDelayReasonInputs;
          return stateDelayReasonInputs;
        });

        if(delayReasonInputId.includes("Other")){//toggle Other textbox
          console.log('www');
          setShowOther(false);
          setIsOtherRequired(false);
          setIsOtherDisabled(true);

          document.getElementsByName('OtherCircumstances')[0].value = '';
          newData['OtherCircumstances']='';
          setInputs({
            ...inputs,
            OtherCircumstances:'',
          });
          setGetFormdata({
            ...getFormdata,
            OtherCircumstances:'',
          });
        }
      }
    } else if(name === 'CovidImpactedQuarters'){
      const quartersInputId = event.target.id;
      console.log(quartersInputs);
      if (event.target.checked) {
        setQuartersInputs(values => ([...values, quartersInputId]));
        setQuartersInputs((stateQuartersInputs) => {
          console.log(stateQuartersInputs);
          newData['CovidImpactedQuarters']=stateQuartersInputs;
          return stateQuartersInputs;
        });
      } else {
        console.log(quartersInputs);
        setQuartersInputs(quartersInputs.filter(e => e !== quartersInputId));
        setQuartersInputs((stateQuartersInputs) => {
          console.log(stateQuartersInputs);
          newData['CovidImpactedQuarters']=stateQuartersInputs;
          return stateQuartersInputs;
        });
      }
    } else {
      newData[name]=value;
    }

    if(document.querySelector('input[name="ReducedServiceHoursReasons"]:checked') !== null &&
    document.querySelector('input[name="CovidImpactedQuarters"]:checked') !== null){//Next button disabled until all inputs are filled
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    console.log(newData);
    setInputs(newData);
    console.log('inputs', inputs);
    console.log('getFormdata', getFormdata);
  }

  const handleDelete = (event) => {
    console.log('handleDelete');
    var key = event.keyCode || event.charCode;

    if( key == 8 || key == 46 ){
      setTimeout(() => {
        console.log(event.target.value);
        if(event.target.value === ''){
          setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      });
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        setInputs({
          ReducedServiceHoursReasons:items.ReducedServiceHoursReasons ? JSON.parse(items.ReducedServiceHoursReasons) : [],
          CovidImpactedQuarters:items.CovidImpactedQuarters ? JSON.parse(items.CovidImpactedQuarters) : [],
          OtherCircumstances:items.OtherCircumstances
        });
        setQuartersInputs(items.CovidImpactedQuarters ? JSON.parse(items.CovidImpactedQuarters) : []);
        setDelayReasonInputs(items.ReducedServiceHoursReasons ? JSON.parse(items.ReducedServiceHoursReasons) : []);

        setGetFormdata(items);
        setGetFormdata((stateGetFormdata) => {
          if(stateGetFormdata.OtherCircumstances !== undefined){
            setShowOther(true);
            setIsOtherRequired(true);
            setIsOtherDisabled(false);
          } else {
            setShowOther(false);
            setIsOtherRequired(false);
            setIsOtherDisabled(true);
          }
          return stateGetFormdata;
        });

        if(items.ReducedServiceHoursReasons !== null &&
          items.CovidImpactedQuarters !== null){//Next button disabled until all inputs are filled
          setNextDisabled(false);
        } else {
          setNextDisabled(true);
        }
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    console.log(inputs);
    var formdata = {token:token, entity_group:entityName, items:inputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        nextStep();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
    //nextStep();
  };

  return (
    <div>
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h5 className="mb-3">Check any and all of the quarters that your business was impacted as a result of Covid {mandatoryEntities.includes("CovidImpactedQuarters") && <span className='txt-red'>*</span>}</h5>
          <Row>
            <Col><Form.Label><b>2019</b></Form.Label></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2019 Q1" label="Quarter 1" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2019 Q1")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2019 Q2" label="Quarter 2" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2019 Q2")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2019 Q3" label="Quarter 3" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2019 Q3")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2019 Q4" label="Quarter 4" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2019 Q4")} /></Col>
          </Row>
          <Row>
            <Col><Form.Label><b>2020</b></Form.Label></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2020 Q1" label="Quarter 1" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2020 Q1")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2020 Q2" label="Quarter 2" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2020 Q2")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2020 Q3" label="Quarter 3" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2020 Q3")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2020 Q4" label="Quarter 4" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2020 Q4")} /></Col>
          </Row>
          <Row>
            <Col><Form.Label><b>2021</b></Form.Label></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2021 Q1" label="Quarter 1" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2021 Q1")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2021 Q2" label="Quarter 2" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2021 Q2")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2021 Q3" label="Quarter 3" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2021 Q3")} /></Col>
            <Col><Form.Check type="checkbox" name="CovidImpactedQuarters" id="2021 Q4" label="Quarter 4" onChange={handleChange} checked={inputs.CovidImpactedQuarters && inputs.CovidImpactedQuarters.includes("2021 Q4")} /></Col>
          </Row>
          <h5 className="mb-3 mt-5">How were employees service hours reduced during the pandemic? {mandatoryEntities.includes("ReducedServiceHoursReasons") && <span className='txt-red'>*</span>}</h5>
          <div>
            <Form.Check type="checkbox" name="ReducedServiceHoursReasons" id="Closure of facilities by government order" label="Closure of facilities by government order" onChange={handleChange} checked={inputs.ReducedServiceHoursReasons && inputs.ReducedServiceHoursReasons.includes("Closure of facilities by government order")} />
            <Form.Check type="checkbox" name="ReducedServiceHoursReasons" id="Supply chain issues" label="Supply chain issues" onChange={handleChange} checked={inputs.ReducedServiceHoursReasons && inputs.ReducedServiceHoursReasons.includes("Supply chain issues")} />
            <Form.Check type="checkbox" name="ReducedServiceHoursReasons" id="Other circumstances specific to your business" label="Other circumstances specific to your business" onChange={handleChange} checked={inputs.ReducedServiceHoursReasons && inputs.ReducedServiceHoursReasons.includes("Other circumstances specific to your business")} />
          </div>
          <div className={showOther ? "d-block" : "d-none"}>
            <Form.Group className="mb-4">
              <Form.Control as="textarea" name="OtherCircumstances" defaultValue={inputs.OtherCircumstances || getFormdata.OtherCircumstances} placeholder="Type here... *" onChange={handleChange} onKeyDown={handleDelete} style={{ height: '100px' }} disabled={isOtherDisabled} required={isOtherRequired} />
            </Form.Group>
          </div>
          {/* <Form.Group className="mb-3">
            <h5>Describe how Covid has negatively impacted the business or changed how it operates since 2019. Be as descriptive as possible </h5>
            <Form.Control as="textarea" name="covidImpact" defaultValue={inputs.covidImpact || getFormdata.covidImpact} onChange={handleChange} style={{ height: '100px' }} />
          </Form.Group> */}
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              {/* <Button variant="primary" type="submit" disabled={nextDisabled}>Next</Button> */}
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Covid19Info;
