import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import Header from '../components/Header';
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const Users = () => {

  const [mToken, setMToken] = useState(sessionStorage.getItem('token'));
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);

  const [userPrivileged, setUserPrivileged] = useState(false);

  const navigate = useNavigate();

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    //let jwt = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJTQXEzRG5SSUwzekxTS1oxeVJuR0xwejJWSy1kajk5TmxSRUgwRENvZTJRIn0.eyJleHAiOjE2NjQxODQwNjgsImlhdCI6MTY2NDE4Mzc2OCwiYXV0aF90aW1lIjoxNjY0MTgxMjg0LCJqdGkiOiIxOGZjYzU3Yy1lNjAyLTQ4YjYtOTQ3OC01MmQ1ZmVhMzJjZjAiLCJpc3MiOiJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2My5hbWJlcm9vbi5jb20vYXV0aC9yZWFsbXMvYXBwcyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1YjIyMTFhMy1mYWM3LTRmYjgtOWI5NC0zMGY1YjU0ODU3MzciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb25zb2xlLXVpIiwibm9uY2UiOiIzNmE1M2FlOS1hMzc4LTRkZDItODA2Yi02NDhlNmIyY2I4YTQiLCJzZXNzaW9uX3N0YXRlIjoiZTMyODRhZDgtZmUzYS00ZjIyLWJjZWQtNTMwZGUwMTI2NTdlIiwiYWNyIjoiMCIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2Mi5hbWJlcm9vbi5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWFwcHMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiZTMyODRhZDgtZmUzYS00ZjIyLWJjZWQtNTMwZGUwMTI2NTdlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiR2VyYXJkIEFudG9ueSIsInByZWZlcnJlZF91c2VybmFtZSI6ImdlcmFyZC5hbnRvbnlAYW1iZXJvb24uY29tIiwiZ2l2ZW5fbmFtZSI6IkdlcmFyZCIsImZhbWlseV9uYW1lIjoiQW50b255IiwiZW1haWwiOiJnZXJhcmQuYW50b255QGFtYmVyb29uLmNvbSJ9.NAUkdgIBEFJeT8cyHzccvB7PirPaD-iPsH5R3XlwZ5L9yAigOm7q03jlEKQlSPbpM5q4ogW_aEwfJt6XF5mIBPouf-2QVWwW82Iqa8X46jaRq0LhJCjdEYuUPaofBXonCaJqyjtiut4V8ZqNw3C_b2QvrG4sCZld3MQpyixRuqLOVSS1g9pIzJLVi7Kxon2EjY7XoBUlqlIIwa0i7vw93zQPoDYK0BLxt2S31LWqWsGmz8x2-cAePtHSq71McXnqLNojeEgQdaAT6XPFfteKMep5yPgrIc5f2Xpvnl49Nj1EoYC6fOUtquEj-qM2BcZAMN4vehlqGKbMmUMI6CRDsg";
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setMToken(response.data.token);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      /* check_user_privilege - read_user_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_user_list'})
      .then(response=>{
        console.log("read_user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 401){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    //if(mToken !== null && mToken !== undefined && userPrivileged){
    if(userPrivileged) {
      if(mToken !== null && mToken !== undefined){
        console.log('mToken', mToken);
        /* get_user_list */
        axios.post(serverConfig.api_base_url + "get_user_list",{token:mToken})
        .then(response=>{
          console.log("get_user_list response:", response);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            //let items = [ {name: 'noam', roles: ['admin'], active: 1, locked: 0, email_id: 'noam.rosario@dbtez.com'}, {name: 'terence', roles: ['admin', 'test'], active: 1, locked: 0, email_id: 'terence.monteiro@amberoon.com'} ]
            console.log(items);
            //setAppDataGridRender(items);
            if (items !== null || items !== undefined){
              let appData = [];
              items.reverse().map((item, index) => {
                let curRole = [];
                curRole = item.roles;
                //console.log(curRole);
                //console.log(Array.of(curRole));
                return (<>
                  {/* {item.roles = [<div><ul>{item.roles.map((i, k) => {
                    return (<li key={k}>{i}</li>)
                  })}</ul><button className='btn-icon ms-2' title='Edit user role'><i className="far fa-edit"></i></button></div>]} */}


                  {item.roles = [<div><ul> { curRole && curRole.map(itemRole => <li>{ itemRole }</li>) }</ul><button className='btn-icon ms-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]}

                  {/* {item.roles = [<div><ul><li>{item.roles}</li></ul><button className='btn-icon ms-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]} */}
              
                  {item.active = item.active === 0 ? (<><button className='btn-icon me-2' title='Enable user' onClick={() => handleActive(item.email_id, 1)}><i className="fas fa-user"></i></button> Disabled</>) : (<><button className='btn-icon me-2' title='Disable user' onClick={() => handleActive(item.email_id, 0)}><i className="far fa-user txt-gray"></i></button> Enabled</>)}

                  {item.locked = item.locked === 0 ? (<><button className='btn-icon me-2' title='Lock user' onClick={() => handleLocked(item.email_id, 1)}><i className="fas fa-lock"></i></button> Unlocked</>) : (<><button className='btn-icon me-2' title='Unlock user' onClick={() => handleLocked(item.email_id, 0)}><i className="fas fa-unlock"></i></button> Locked</>)}

                  {item.actions = (<><button className='btn-icon me-4' onClick={() => handleExPWModal(item.name, item.email_id)}><u>Expire password</u></button><button className='btn-icon me-2' onClick={() => handleChPWModal(item.name, item.email_id)}><u>Change password</u></button></>)}

                  {appData.push(item)}
                </>);
              });
              console.log(appData);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status === 401){
            alert(response.data.message);
            //getToken();
          }
        })
        .catch(err=>console.log("error is",err));

        /* get_role_list */
        axios.post(serverConfig.api_base_url + "get_role_list",{token: mToken})
        .then(response=>{
          console.log('get_role_list response', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setRolesList(JSON.parse(response.data.items));
          } else if(response.data.status === 401){
            alert(response.data.message);
            //getToken();
            //alert('Inactive session. Please try again.');
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [mToken, actions, userPrivileged]);

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: true, key: 'name' },
      { label: 'Email Id', field: 'email_id', sort: false, key: 'email_id' },
      { label: 'Active', field: 'active', sort: false, key: 'active' },
      { label: 'Locked', field: 'locked', sort: false, key: 'locked' },
      { label: 'Roles', field: 'roles', sort: false, key: 'roles' },
      { label: 'Actions', field: 'actions', sort: false, key: 'roles' },
    ],
    rows: appDataGridRender,
  };

  const [rolesList, setRolesList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [rolesInputs, setRolesInputs] = useState([]);
  const [curUserRole, setCurUserRole] = useState([]);

  const [rolesModalShow, setRolesModalShow] = useState(false);
  const handleCloseRolesModal = () => {
    setRolesInputs([]);//clear roles checkbox on close of modal
    setCurUserRole([]);
    setCurrentUser(null);
    setCurrentUserEmail(null);
    setCurrentUserRole(null);
    setClicked(false);
    setRolesModalShow(false);
  }

  const handleRolesModal = (username, useremail, roles) => {
    console.log(username, useremail, roles);
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setCurrentUserRole(roles);
    setRolesModalShow(true);
  }

  const [clicked, setClicked] = useState(false);
  const handleCheckboxChange = (event) => {
    const roleId = event.target.id;
    //console.log('clicked', clicked);
    if(!clicked){
      if(currentUserRole !== null && currentUserRole.length){
        setClicked(true);
        setRolesInputs(currentUserRole);
      }
    }
    if (event.target.checked) {
      setRolesInputs(values => ([...values, roleId ]));
    } else {
      if(clicked){
        setRolesInputs(rolesInputs.filter(e => e !== roleId));
      } else {
        setRolesInputs(currentUserRole && currentUserRole.filter(e => e !== roleId));
      }
    }
  }

  const handleGrantRole = () => {
    //let roles = [...curUserRole, ...rolesInputs];
    //console.log(roles);
    console.log(rolesInputs);

    if(rolesInputs.length){//call the API only if any changes done (checked / unchecked)
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: mToken, email_id: currentUserEmail, roles: rolesInputs})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success');
          setActions(!actions);// to refresh user list after role is updated
          handleCloseRolesModal();
        } else if(response.data.status === 401){
          alert(response.data.message);
          //getToken();
          //alert('Inactive session. Please try again.');
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handleRevokeRole = () => {
    //let roles = [...curUserRole, ...rolesInputs];
    //console.log(roles);
    
    console.log(rolesInputs);

    var roles;
    if(rolesInputs.length){
      roles = rolesInputs;
    } else {
      if(currentUserRole !== null && currentUserRole.length && !clicked){
        roles = currentUserRole
      }
    }

    console.log(roles);

    if(roles !== undefined && roles.length){
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: mToken, email_id: currentUserEmail, roles: roles})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success');
          setActions(!actions);// to refresh user list after role is updated
          handleCloseRolesModal();
        } else if(response.data.status === 401){
          alert(response.data.message);
          //getToken();
          //alert('Inactive session. Please try again.');
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const [exPWMsg, setExPWMsg] = useState("");
  const [showExPWModal, setShowExPWModal] = useState(false);
  const closeExPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setExPWMsg("");
    setShowExPWModal(false);
  }
  const handleExPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowExPWModal(true);
  }
  const handleExpirePwd = (e) => {
    e.preventDefault();
    console.log({token: mToken, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_expire_password",{token: mToken, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_expire_password response', response);
      if(response.data.status === 200){
        console.log('success');
        setExPWMsg("Expired password successfully");
        setTimeout(() => {
          closeExPWModal();
        }, 1000);
      } else if(response.data.status === 401){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [chPWMsg, setChPWMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChPWModal, setShowChPWModal] = useState(false);
  const closeChPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setChPWMsg("");
    setNewPassword("");
    setShowChPWModal(false);
  }
  const handleChPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowChPWModal(true);
  }
  const handleChangePwd = (e) => {
    e.preventDefault();
    console.log({token: mToken, email_id: currentUserEmail, password: newPassword});
    axios.post(serverConfig.api_base_url + "user_change_password ",{token: mToken, email_id: currentUserEmail, password: newPassword})
    .then(response=>{
      console.log('user_change_password  response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Password changed successfully");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 401){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: mToken, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 401){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: mToken, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 401){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        setActions(!actions);// to refresh user list after user is created
        setCreateUserModalShow(false);
      } else if(response.data.status === 401){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <div className='page-heading'>
            <h1>Users</h1>
            {/* Create User */}
            <Button variant="transparent" className='btn-txt-black' onClick={() => setCreateUserModalShow(true)}>Create</Button>
            <Modal show={createUserModalShow} onHide={() => setCreateUserModalShow(false)} backdrop="static"keyboard={false} centered>
              <Form onSubmit={handleCreateUser}>
                <Modal.Header closeButton>
                  <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email Id" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="transparent" onClick={() => setCreateUserModalShow(false)}>Cancel</Button>
                  <Button variant="primary" type="submit" className='ms-3'><i className="fas fa-user-plus me-2"></i>Create</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>

          {/* User list table */}
          <section>
            <div className='datatable datatable-users'>
              <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here" />
            </div>
          </section>

          {/* Edit user role */}
          <Modal show={rolesModalShow} onHide={handleCloseRolesModal} backdrop="static"keyboard={false} centered>
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Edit User Role</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Edit roles for {currentUser}</p>
                <Row>
                  {rolesList.map((item, index) => (
                    <Col key={index}><Form.Check type="checkbox" id={item} value={item} label={item} defaultChecked={currentUserRole && currentUserRole.includes(item)} onChange={handleCheckboxChange} /></Col>
                  ))}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseRolesModal}>Cancel</Button>
                <Button variant="primary" onClick={handleGrantRole} className='ms-3'>Grant</Button>
                <Button variant="primary" onClick={handleRevokeRole} className='ms-3'>Revoke</Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Expire Password */}
          <Modal show={showExPWModal} onHide={closeExPWModal} backdrop="static"keyboard={false} centered>
            <Form onSubmit={handleExpirePwd}>
              <Modal.Header closeButton>
                <Modal.Title>Expire Passowrd</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Do you want to proceed with expire password for {currentUser}?</p>
                <p className="txt-green">{exPWMsg}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={closeExPWModal}>No</Button>
                <Button variant="primary" type="submit" className='ms-3'>Yes</Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Change Password */}
          <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered>
            <Form onSubmit={handleChangePwd}>
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Change password for {currentUser}</p>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="New password" name="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                </Form.Group>
                <p className="txt-green">{chPWMsg}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={closeChPWModal}>Cancel</Button>
                <Button variant="primary" type="submit" className='ms-3'>Change</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Container>
      </main>
    </>
  );
}

export default Users;
