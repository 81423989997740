import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const PowerOfAttorney = ({ nextStep, prevStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});
  
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form2848Name, setForm2848Name] = useState('');
  const [form8821Name, setForm8821Name] = useState('');
  const [form8822BName, setForm8822BName] = useState('');

  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='pdf' || extension=='csv' || extension=='jpg' || extension=='png' || extension=='xml' || extension=='json' || extension=='xbrl' || extension=='txt' || extension=='prn'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      //console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        if(name === 'Form2848'){
          setForm2848Name(filename);
        } else if(name === 'Form8821'){
          setForm8821Name(filename);
        } else if(name === 'Form8822B'){
          setForm8822BName(filename);
        }
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          //console.log(fileData);
          //newData[name]=fileData;
          //newData[name+'_filename']=filename;
          axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
            } else if(response.data.status === 401){
              getToken();
              alert('Inactive session. Please upload the file again.');
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only pdf, csv, jpg, png, xml, json, xbrl, txt, prn files are allowed");
      event.target.value = null;
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        setForm2848Name(items.Form2848);
        setForm8821Name(items.Form8821);
        setForm8822BName(items.Form8822B);
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="position-relative">
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h1>Please upload the following documents:</h1>
          <h5><b>Form 2848</b> (Power of Attorney and Declaration of Representative)  {mandatoryEntities.includes("Form2848") && <span className='txt-red'>*</span>}</h5>
          <div className="position-relative mt-4 mb-5">
            <Form.Label htmlFor="Form2848" className='upload'>{form2848Name ? form2848Name : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u></>}</Form.Label>
            <Form.Control id="Form2848" name="Form2848" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
          </div>
          <h5><b>Form 8821</b> (Tax Information Authorization) {mandatoryEntities.includes("Form8821") && <span className='txt-red'>*</span>}</h5>
          <div className="position-relative mt-4 mb-5">
            <Form.Label htmlFor="Form8821" className='upload'>{form8821Name ? form8821Name : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u></>}</Form.Label>
            <Form.Control id="Form8821" name="Form8821" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
          </div>
          <h5><b>Form 8822B</b> (Change of Address or Responsible Party - Business) {mandatoryEntities.includes("Form8822B") && <span className='txt-red'>*</span>}</h5>
          <div className="position-relative mt-4">
            <Form.Label htmlFor="Form8822B" className='upload'>{form8822BName ? form8822BName : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u></>}</Form.Label>
            <Form.Control id="Form8822B" name="Form8822B" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
          </div>
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default PowerOfAttorney;
