import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const CompanyInfo = ({ nextStep, token, entityName, getToken }) => {

  const[showMailingAddr, setShowMailingAddr] = useState(false);
  const[mailingAddrChecked, setMailingAddrChecked] = useState(false);

  const[isMailAddrRequired, setIsMailAddrRequired] = useState(false);
  const[isMailAddrDisabled, setIsMailAddrDisabled] = useState(true);

  const[getFormdata, setGetFormdata] = useState({});

  const [selectedPayrollDoneBy, setSelectedPayrollDoneBy] = useState('');
  const [selected, setSelected] = useState('');

  const [inputs, setInputs] = useState({});

  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const handleDiffMailAddr = (event) => {
    if (event.target.checked) {
      setShowMailingAddr(true);
      setIsMailAddrRequired(true);
      setIsMailAddrDisabled(false);
      setMailingAddrChecked(true);
    } else {
      setShowMailingAddr(false);
      setIsMailAddrRequired(false);
      setIsMailAddrDisabled(true);
      setMailingAddrChecked(false);

      document.getElementsByName('MailingAddressLine1')[0].value = '';
      document.getElementsByName('MailingAddressLine2')[0].value = '';
      document.getElementsByName('MailingCity')[0].value = '';
      document.getElementsByName('MailingState')[0].value = '';
      document.getElementsByName('MailingPostalCode')[0].value = '';
      setInputs({
        ...inputs,
        MailingAddressLine1:'',
        MailingAddressLine2:'',
        MailingCity:'',
        MailingState:'',
        MailingPostalCode:'',
      });
      setGetFormdata({
        ...getFormdata,
        MailingAddressLine1:'',
        MailingAddressLine2:'',
        MailingCity:'',
        MailingState:'',
        MailingPostalCode:'',
      });
    }
  }

  const handleChange = (event) => {
    console.log('changing');
    const name = event.target.name;
    let value = event.target.value;

    const newData={...inputs}

    if(name==="PayrollByPEO"){
      setSelectedPayrollDoneBy(event.target.value);
    }
    if(name==="EntityFillingType"){
      setSelected(event.target.value);
    }
    if(name==="AnnualCompanyRevenue"){
      let withoutComma = value.replace(/,/g, '');
      event.target.value = Number(withoutComma).toLocaleString("en-US");
      value = Number(withoutComma).toLocaleString("en-US");
    }
    newData[name]=value;

    console.log(newData);
    setInputs(newData);
    console.log('inputs', inputs);
    console.log('getFormdata', getFormdata);
  }

  const handleDelete = (event) => {
    console.log('handleDelete');
    var key = event.keyCode || event.charCode;

    if( key == 8 || key == 46 ){
      setTimeout(() => {
        console.log(event.target.value);
        if(event.target.value === 'NaN'){
          event.target.value = '';
        }
        if(event.target.value === ''){
          setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      });
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    console.log(token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        setInputs({
          CompanyName:items.CompanyName,
          EINNumber:items.EINNumber,
          TradeNameDBA:items.TradeNameDBA,
          Industry:items.Industry,
          AnnualCompanyRevenue:items.AnnualCompanyRevenue,
          PayrollByPEO:items.PayrollByPEO,
          EntityFillingType:items.EntityFillingType,
          WebsiteURL:items.WebsiteURL,
          PermanentAddressLine1:items.PermanentAddressLine1,
          PermanentAddressLine2:items.PermanentAddressLine2,
          PermanentState:items.PermanentState,
          PermanentCity:items.PermanentCity,
          PermanentPostalCode:items.PermanentPostalCode,
          MailingAddressLine1:items.MailingAddressLine1,
          MailingAddressLine2:items.MailingAddressLine2,
          MailingState:items.MailingState,
          MailingCity:items.MailingCity,
          MailingPostalCode:items.MailingPostalCode,
        });
        setGetFormdata(items);
        setGetFormdata((stateGetFormdata) => {
          if(stateGetFormdata.MailingAddressLine1 !== undefined){
            setShowMailingAddr(true);
            setIsMailAddrRequired(true);
            setIsMailAddrDisabled(false);
            setMailingAddrChecked(true);
          } else {
            setShowMailingAddr(false);
            setIsMailAddrRequired(false);
            setIsMailAddrDisabled(true);
            setMailingAddrChecked(false);
          }
          return stateGetFormdata;
        });

        if(items.PayrollByPEO === 'Yes') {
          setSelectedPayrollDoneBy('Yes');
        } else if(items.PayrollByPEO === 'No') {
          setSelectedPayrollDoneBy('No');
        }

        if(items.EntityFillingType === 'C-Corp') {
          setSelected('C-Corp');
        } else if(items.EntityFillingType === 'S-Corp') {
          setSelected('S-Corp');
        } else if(items.EntityFillingType === 'LLC') {
          setSelected('LLC');
        } else if(items.EntityFillingType === 'Sole-Proprietorship') {
          setSelected('Sole-Proprietorship');
        } else if(items.EntityFillingType === 'Partnership') {
          setSelected('Partnership');
        } else if(items.EntityFillingType === '501(c) Tax Exempt') {
          setSelected('501(c) Tax Exempt');
        }
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    console.log(inputs);
    console.log(getFormdata);
    var formdata = {token:token, entity_group:entityName, items:inputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        nextStep();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  };

  return (
    <div>
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Legal Company Name {mandatoryEntities.includes("CompanyName") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="CompanyName" defaultValue={inputs.CompanyName || getFormdata.CompanyName} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>EIN Number {mandatoryEntities.includes("EINNumber") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="EINNumber" defaultValue={inputs.EINNumber || getFormdata.EINNumber} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Trade name DBA {mandatoryEntities.includes("TradeNameDBA") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="TradeNameDBA" defaultValue={inputs.TradeNameDBA || getFormdata.TradeNameDBA} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Industry {mandatoryEntities.includes("Industry") && <span className='txt-red'>*</span>}</Form.Label>
                {/* <Form.Select aria-label="Default select example" name="Industry" value={inputs.Industry || getFormdata.Industry} onChange={handleChange}>
                  <option></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                <Form.Control name="Industry" defaultValue={inputs.Industry || getFormdata.Industry} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Group className="mb-4">
                <Form.Label>
                  Approximately, what is your annual company revenue? {mandatoryEntities.includes("AnnualCompanyRevenue") && <span className='txt-red'>*</span>}
                  <OverlayTrigger placement="right" overlay={<Tooltip>Info</Tooltip>}>
                    <span className="info"><i>i</i></span>
                  </OverlayTrigger>
                </Form.Label>
                {/* <Form.Select aria-label="Default select example" name="AnnualCompanyRevenue" value={inputs.AnnualCompanyRevenue || getFormdata.AnnualCompanyRevenue} onChange={handleChange}>
                  <option></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                <Form.Control name="AnnualCompanyRevenue" defaultValue={inputs.AnnualCompanyRevenue || getFormdata.AnnualCompanyRevenue} onChange={handleChange} onKeyDown={handleDelete} type="text" pattern="^[0-9,]*$" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="mb-3">Is your payroll done by a Professional Employer Organization (PEO) ?{mandatoryEntities.includes("PayrollByPEO") && <span className='txt-red'>*</span>}</Form.Label>
                <div className="d-flex">
                  <Form.Check inline type="radio" name="PayrollByPEO" value={"Yes" || ""} onChange={handleChange} id="Yes" label="Yes" checked={selectedPayrollDoneBy === 'Yes'} />
                  <Form.Check inline type="radio" name="PayrollByPEO" value={"No" || ""} onChange={handleChange} id="No" label="No" checked={selectedPayrollDoneBy === 'No'} />
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="mb-3">Entity filling type: {mandatoryEntities.includes("EntityFillingType") && <span className='txt-red'>*</span>}</Form.Label>
                <div className="d-flex justify-content-between">
                  <Form.Check inline type="radio" name="EntityFillingType" value={"C-Corp" || ""} onChange={handleChange} id="C-Corp" label="C-Corp" checked={selected === 'C-Corp'} />
                  <Form.Check inline type="radio" name="EntityFillingType" value={"S-Corp" || ""} onChange={handleChange} id="S-Corp" label="S-Corp" checked={selected === 'S-Corp'} />
                  <Form.Check inline type="radio" name="EntityFillingType" value={"LLC" || ""} onChange={handleChange} id="LLC" label="LLC" checked={selected === 'LLC'} />
                  <Form.Check inline type="radio" name="EntityFillingType" value={"Sole-Proprietorship" || ""} onChange={handleChange} id="Sole-Proprietorship" label="Sole-Proprietorship" checked={selected === 'Sole-Proprietorship'} />
                  <Form.Check inline type="radio" name="EntityFillingType" value={"Partnership" || ""} onChange={handleChange} id="Partnership" label="Partnership" checked={selected === 'Partnership'} />
                  <Form.Check inline type="radio" name="EntityFillingType" value={"501(c) Tax Exempt" || ""} onChange={handleChange} id="501(c) Tax Exempt" label="501(c) Tax Exempt" checked={selected === '501(c) Tax Exempt'} />
                </div>
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label>Website URL {mandatoryEntities.includes("WebsiteURL") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="WebsiteURL" defaultValue={inputs.WebsiteURL || getFormdata.WebsiteURL} onChange={handleChange} onKeyDown={handleDelete} type="url" />
              </Form.Group>
              <h5 className="mb-3"><b>Permanent Address</b></h5>
              <Form.Group className="mb-4">
                <Form.Label>Address Line 1 {mandatoryEntities.includes("PermanentAddressLine1") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="PermanentAddressLine1" defaultValue={inputs.PermanentAddressLine1 || getFormdata.PermanentAddressLine1} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Address Line 2 {mandatoryEntities.includes("PermanentAddressLine2") && <span className='txt-red'>*</span>}</Form.Label>
                <Form.Control name="PermanentAddressLine2" defaultValue={inputs.PermanentAddressLine2 || getFormdata.PermanentAddressLine2} onChange={handleChange} onKeyDown={handleDelete} type="text" />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>State {mandatoryEntities.includes("PermanentState") && <span className='txt-red'>*</span>}</Form.Label>
                    {/* <br/>inputs.state - {inputs.state}, getFormdata.state - {getFormdata.state} */}
                    <Form.Control name="PermanentState" defaultValue={inputs.PermanentState || getFormdata.PermanentState} onChange={handleChange} onKeyDown={handleDelete} type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>City {mandatoryEntities.includes("PermanentCity") && <span className='txt-red'>*</span>}</Form.Label>
                    <Form.Control name="PermanentCity" defaultValue={inputs.PermanentCity || getFormdata.PermanentCity} onChange={handleChange} onKeyDown={handleDelete} type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>Postal Code {mandatoryEntities.includes("PermanentPostalCode") && <span className='txt-red'>*</span>}</Form.Label>
                    <Form.Control name="PermanentPostalCode" defaultValue={inputs.PermanentPostalCode || getFormdata.PermanentPostalCode} onChange={handleChange} onKeyDown={handleDelete} type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="mb-3 mt-3"><b>Mailing Address</b></h5>
              <Form.Group className="mb-4">
                <Form.Check type="checkbox" label="Is different from above" onChange={handleDiffMailAddr} checked={mailingAddrChecked} />
              </Form.Group>
              <div className={showMailingAddr ? "d-block" : "d-none"}>
                <Form.Group className="mb-4">
                  <Form.Label>Address Line 1 <span className='txt-red'>*</span></Form.Label>
                  <Form.Control name="MailingAddressLine1" defaultValue={inputs.MailingAddressLine1 || getFormdata.MailingAddressLine1} onChange={handleChange} onKeyDown={handleDelete} type="text" disabled={isMailAddrDisabled} required={isMailAddrRequired} />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Address Line 2</Form.Label>
                  <Form.Control name="MailingAddressLine2" defaultValue={inputs.MailingAddressLine2 || getFormdata.MailingAddressLine2} onChange={handleChange} onKeyDown={handleDelete} type="text" disabled={isMailAddrDisabled} />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-4">
                      <Form.Label>State <span className='txt-red'>*</span></Form.Label>
                      <Form.Control name="MailingState" defaultValue={inputs.MailingState || getFormdata.MailingState} onChange={handleChange} disabled={isMailAddrDisabled} required={isMailAddrRequired} onKeyDown={handleDelete} type="text" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4">
                      <Form.Label>City <span className='txt-red'>*</span></Form.Label>
                      <Form.Control name="MailingCity" defaultValue={inputs.MailingCity || getFormdata.MailingCity} onChange={handleChange} disabled={isMailAddrDisabled} required={isMailAddrRequired} onKeyDown={handleDelete} type="text" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4">
                      <Form.Label>Postal Code <span className='txt-red'>*</span></Form.Label>
                      <Form.Control name="MailingPostalCode" defaultValue={inputs.MailingPostalCode || getFormdata.MailingPostalCode} onChange={handleChange} onKeyDown={handleDelete} type="text" disabled={isMailAddrDisabled} required={isMailAddrRequired} />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={e => window.location.href="https://www.amberoon.com/"}>Cancel</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default CompanyInfo;
