import React, {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from "mdbreact";
import axios from "axios";

const serverConfig = require("../config/server.js");

function AppDataGrid({show, refreshAppList, getAppCount, token, isUserPrivileged}) {
  
  //const mToken = sessionStorage.getItem('mToken');
  const mToken = token;

  const [appDataGrid, setAppDataGrid] = useState([]);
  const [appDataGridRender, setAppDataGridRender] = useState([]);

  const [CPAList, setCPAList] = useState([]);

  const handleClick = (link) => {
    console.log('clicked', link);
    window.location = link;
  }

  const handleApprove = (idNum, name, emailid) => {
    console.log(idNum, name, emailid);
    axios.post(serverConfig.api_base_url + "user_create_customer_access",{token:mToken, email_id:emailid})
    .then(response=>{
      console.log('user_create_customer_access response', response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
      } else if(response.data.status === 401){
        alert(response.data.message);
      } else if(response.data.status === 501){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log('props', show);
    console.log(mToken, sessionStorage.getItem('mToken'));
    
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken, show);
      //get_access_request_list
      axios.post(serverConfig.api_base_url + "get_access_request_list",{filter_check:show, token:mToken})
      .then(response=>{
        console.log('get_access_request_list response', response);
        if(response.data.status === 200){
          let items = JSON.parse(response.data.items);
          if (items !== null || items !== undefined){
            console.log("get_access_request_list response items:", items);
            setAppDataGrid(items);
            setAppDataGrid((stateAppDataGrid) => {
              let appDataArray = JSON.parse(JSON.stringify(stateAppDataGrid)); // deep copy?
              let appData = [];
              console.log(appDataArray);
              //appDataArray.sort((a, b) => b.last_updated.localeCompare(a.last_updated)).map((item, index) => {
              appDataArray && appDataArray.map((item, index) => {
                let id_no = item.application_id;
                if(item.is_approved == 0){
                  item.is_approved = 'Pending Approval';
                  item.actions = [<span onClick={() => handleApprove(id_no, item.contact_name, item.email_id)} className='approve-icon' title="Approve"><img src={require('./../assets/images/approve-icon.png')} alt="Approve Icon" className='me-2' /></span>];
                } else if(item.is_approved == 1){
                  item.is_approved = 'Approved';
                }
                appData.push(item);
              });
              console.log(appData);
              setAppDataGridRender(appData);
              return stateAppDataGrid;
            });
          }
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [show, isUserPrivileged, mToken, CPAList]);

  const data = {
    columns: [
      {
        label: 'ID No.',
        field: 'application_id',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Contact Person',
        field: 'contact_name',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Email ID',
        field: 'email_id',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Phone Number',
        field: 'mobile_number',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Status',
        field: 'is_approved',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      }
    ],

    rows: appDataGridRender,
  };

  return (
    <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here"/>
  );
}

export default AppDataGrid;
