import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const Payroll = ({ prevStep, nextStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});

  const [inputs, setInputs] = useState({});
  const [fileInputs, setFileInputs] = useState({});
  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const newData={...inputs}

    newData[name]=value;
    setInputs(newData);
  }

  const handleDelete = (event) => {
    console.log('handleDelete');
    var key = event.keyCode || event.charCode;
  
    if( key == 8 || key == 46 ){
      setTimeout(() => {
        console.log(event.target.value);
        if(event.target.value === ''){
          setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      });
    }
  }

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='pdf' || extension=='csv' || extension=='jpg' || extension=='png' || extension=='xml' || extension=='json' || extension=='xbrl' || extension=='txt' || extension=='prn'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      //console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          //console.log(fileData);
          setFileInputs({
            ...fileInputs,
            [name]:filename,
          });
          axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only pdf, csv, jpg, png, xml, json, xbrl, txt, prn files are allowed");
      event.target.value = null;
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        /* setInputs({
          EmployeesHavingMoreThan50PerOwnership:items.EmployeesHavingMoreThan50PerOwnership,
        }); */
        setGetFormdata(items);
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('inputs', inputs);
    console.log('fileInputs', fileInputs);
    var formdata = {token:token, entity_group:entityName, items:inputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        setIsLoading(false);
        console.log('success');
        nextStep();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  };

  return (
    <div className="position-relative">
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h1>Payroll</h1>
          <h5 className="mb-4">Please provide payroll data for each of the quarters below. This must itemize payroll information for each W2 employee</h5>
          <Table striped borderless>
            <thead>
              <tr>
                <th width="10%">Calculation Year</th>
                <th width="22%">Quarter 1 (Jan - Mar)</th>
                <th width="22%">Quarter 2 (Apr - Jun)</th>
                <th width="22%">Quarter 3 (Jul - Sep)</th>
                <th width="22%">Quarter 4 (Oct - Dec)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>2019</th>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2019Q1File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2019Q1File") && '*'}</span>
                    {getFormdata.Payroll2019Q1File ? (fileInputs.Payroll2019Q1File || getFormdata.Payroll2019Q1File) : (fileInputs.Payroll2019Q1File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2019Q1File" name="Payroll2019Q1File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2019Q2File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2019Q2File") && '*'}</span>
                    {getFormdata.Payroll2019Q2File ? (fileInputs.Payroll2019Q2File || getFormdata.Payroll2019Q2File) : (fileInputs.Payroll2019Q2File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2019Q2File" name="Payroll2019Q2File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2019Q3File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2019Q3File") && '*'}</span>
                    {getFormdata.Payroll2019Q3File ? (fileInputs.Payroll2019Q3File || getFormdata.Payroll2019Q3File) : (fileInputs.Payroll2019Q3File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2019Q3File" name="Payroll2019Q3File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2019Q4File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2019Q4File") && '*'}</span>
                    {getFormdata.Payroll2019Q4File ? (fileInputs.Payroll2019Q4File || getFormdata.Payroll2019Q4File) : (fileInputs.Payroll2019Q4File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2019Q4File" name="Payroll2019Q4File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>2020</th>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2020Q1File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2020Q1File") && '*'}</span>
                    {getFormdata.Payroll2020Q1File ? (fileInputs.Payroll2020Q1File || getFormdata.Payroll2020Q1File) : (fileInputs.Payroll2020Q1File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2020Q1File" name="Payroll2020Q1File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2020Q2File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2020Q2File") && '*'}</span>
                    {getFormdata.Payroll2020Q2File ? (fileInputs.Payroll2020Q2File || getFormdata.Payroll2020Q2File) : (fileInputs.Payroll2020Q2File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2020Q2File" name="Payroll2020Q2File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2020Q3File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2020Q3File") && '*'}</span>
                    {getFormdata.Payroll2020Q3File ? (fileInputs.Payroll2020Q3File || getFormdata.Payroll2020Q3File) : (fileInputs.Payroll2020Q3File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2020Q3File" name="Payroll2020Q3File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2020Q4File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2020Q4File") && '*'}</span>
                    {getFormdata.Payroll2020Q4File ? (fileInputs.Payroll2020Q4File || getFormdata.Payroll2020Q4File) : (fileInputs.Payroll2020Q4File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2020Q4File" name="Payroll2020Q4File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>2021</th>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2021Q1File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2021Q1File") && '*'}</span>
                    {getFormdata.Payroll2021Q1File ? (fileInputs.Payroll2021Q1File || getFormdata.Payroll2021Q1File) : (fileInputs.Payroll2021Q1File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2021Q1File" name="Payroll2021Q1File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2021Q2File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2021Q2File") && '*'}</span>
                    {getFormdata.Payroll2021Q2File ? (fileInputs.Payroll2021Q2File || getFormdata.Payroll2021Q2File) : (fileInputs.Payroll2021Q2File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2021Q2File" name="Payroll2021Q2File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2021Q3File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2021Q3File") && '*'}</span>
                    {getFormdata.Payroll2021Q3File ? (fileInputs.Payroll2021Q3File || getFormdata.Payroll2021Q3File) : (fileInputs.Payroll2021Q3File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2021Q3File" name="Payroll2021Q3File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Payroll2021Q4File" className="upload upload-default">
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Payroll2021Q4File") && '*'}</span>
                    {getFormdata.Payroll2021Q4File ? (fileInputs.Payroll2021Q4File || getFormdata.Payroll2021Q4File) : (fileInputs.Payroll2021Q4File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Payroll2021Q4File" name="Payroll2021Q4File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>

          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Payroll;
