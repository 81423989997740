import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const AgreementsDisclosures = ({ nextStep, prevStep, token, entityName, getToken, refreshSidebarCompletedStatus }) => {

  const [mandatoryEntities, setMandatoryEntities] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  //Terms and Conditions
  const [checkedTC, setCheckedTC] = useState(false);

  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const handleCloseTermsConditions = () => setShowTermsConditions(false);
  const handleShowTermsConditions = () => setShowTermsConditions(true);

  const [agreeDisabledTermsConditions, setAgreeDisabledTermsConditions] = useState(true);
  const handleScrollTC = (e) => {
    const bottom = (e.target.scrollHeight * 98 / 100) <= (e.target.clientHeight + e.target.scrollTop);
    if (bottom) {
      setAgreeDisabledTermsConditions(false);
    }
  }

  const handleDisagreeTermsConditions = () => {
    setCheckedTC(false);
    var formdata = {token:token, entity_group:entityName, items:{TermsAndConditions: ''}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        handleCloseTermsConditions();
        getCompletedStatusFn();
        refreshSidebarCompletedStatus();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleAgreeTermsConditions = () => {
    setCheckedTC(true);

    var formdata = {token:token, entity_group:entityName, items:{TermsAndConditions: true}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        handleCloseTermsConditions();
        getCompletedStatusFn();
        refreshSidebarCompletedStatus();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Privacy Policy
  const [checkedPP, setCheckedPP] = useState(false);

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
  const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);

  const [agreeDisabledPrivacyPolicy, setAgreeDisabledPrivacyPolicy] = useState(true);
  const handleScrollPP = (e) => {
    const bottom = (e.target.scrollHeight * 98 / 100) <= (e.target.clientHeight + e.target.scrollTop);
    if (bottom) {
      setAgreeDisabledPrivacyPolicy(false);
    }
  }

  const handleDisagreePrivacyPolicy = () => {
    setCheckedPP(false);

    var formdata = {token:token, entity_group:entityName, items:{PrivacyPolicy: ''}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        handleClosePrivacyPolicy();
        getCompletedStatusFn();
        refreshSidebarCompletedStatus();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleAgreePrivacyPolicy = () => {
    setCheckedPP(true);

    var formdata = {token:token, entity_group:entityName, items:{PrivacyPolicy: true}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        handleClosePrivacyPolicy();
        getCompletedStatusFn();
        refreshSidebarCompletedStatus();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getCompletedStatusFn = () => {
    axios.post(serverConfig.api_base_url + "get_completed_status",{token: token})
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        let res = JSON.parse(response.data.items);
        console.log(res);
        if(res !== null){
          let items = Object.assign({}, ...res);
          console.log(items);
          console.log(Object.values(items));
          if(items.agreements_disclosures === 'C' && items.company_info === 'C' && items.covid19_info === 'C' && items.document_info === 'C' && items.employee_info === 'C' && items.form_941 === 'C' && items.gross_receipts === 'C' && items.payroll === 'C' && items.ppp_loans === 'C' && items.power_of_attorney === 'C'){
            setSubmitDisabled(false);
          } else {
            setSubmitDisabled(true);
          }
        }
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log(response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        {items.TermsAndConditions && setCheckedTC(JSON.parse(items.TermsAndConditions))};//JSON.parse -> convert string true or false to boolean true or false
        {items.PrivacyPolicy && setCheckedPP(JSON.parse(items.PrivacyPolicy))};//JSON.parse -> convert string true or false to boolean true or false
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));

    getCompletedStatusFn();
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    var formdata = {token:token, entity_group:entityName, items:{Submitted: true}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        nextStep();
      } else if(response.data.status === 401){
        getToken();
        alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  };

  return (
    <div>
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h1>Agreements &amp; Disclosures</h1>
          <Form.Group className="mb-2">
            <div onClick={handleShowTermsConditions} className='agreements-check'>
              <Form.Check type="checkbox" checked={checkedTC} label={mandatoryEntities.includes("TermsAndConditions") ? (<>I have read and I agree with the <u>Terms and Conditions</u> <span className='txt-red'>*</span></>) : (<>I have read and I agree with the <u>Terms and Conditions</u></>)} required={mandatoryEntities.includes("TermsAndConditions")} />
            </div>
            <Modal show={showTermsConditions} onHide={handleCloseTermsConditions} centered backdrop="static"keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div onScroll={handleScrollTC}>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleDisagreeTermsConditions}>Cancel</Button>
                {agreeDisabledTermsConditions ?
                  <Button variant="primary" disabled>Agree</Button> : <Button variant="primary" onClick={handleAgreeTermsConditions}>Agree</Button>
                }
              </Modal.Footer>
            </Modal>
          </Form.Group>

          <Form.Group className="mb-2">
            <div onClick={handleShowPrivacyPolicy} className='agreements-check'>
              <Form.Check type="checkbox" checked={checkedPP} label={mandatoryEntities.includes("PrivacyPolicy") ? (<>I have read and I agree with the <u>Privacy policy</u> <span className='txt-red'>*</span></>) : (<>I have read and I agree with the <u>Privacy policy</u></>)} required={mandatoryEntities.includes("PrivacyPolicy")} />
            </div>
            <Modal show={showPrivacyPolicy} onHide={handleClosePrivacyPolicy} centered backdrop="static"keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Privacy policy</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div onScroll={handleScrollPP}>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleDisagreePrivacyPolicy}>Cancel</Button>
                {agreeDisabledPrivacyPolicy ?
                  <Button variant="primary" disabled>Agree</Button> : <Button variant="primary" onClick={handleAgreePrivacyPolicy}>Agree</Button>
                }
              </Modal.Footer>
            </Modal>
          </Form.Group>
          
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit" disabled={submitDisabled}>Submit</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default AgreementsDisclosures;
