import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import '../assets/css/styles.css';
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import Users from '../pages/Users';
import Signup from '../pages/Signup';
import Applications from '../pages/Applications';

function App() {
  return (
    <BrowserRouter>
      <RenderOnAnonymous />
      <RenderOnAuthenticated>
        <Routes>
          <Route path="/" element={<Signup />} />
          <Route path="/users" element={<Users />} />
          <Route path="/applications" element={<Applications />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
