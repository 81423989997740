import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";
import AppDataGrid from '../components/AppDataGrid';

const serverConfig = require("../config/server.js");

const Applications = () => {

  const [mToken, setMToken] = useState(sessionStorage.getItem('token'));

  const [userPrivileged, setUserPrivileged] = useState(false);
  const [applicationCount, setApplicationCount] = useState({});
  const [showApplications, setShowApplications] = useState('all');

  const navigate = useNavigate();

  //send invite to customer - start
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setInviteSentMessage('');
    setShowInvitePopup(false);
  }
  const handleShowInvitePopup = () => setShowInvitePopup(true);

  const [inviteSentMessage, setInviteSentMessage] = useState('');
  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  const [inputs, setInputs] = useState({});
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_create_invite",formdata)
    .then(response=>{
      console.log("user_create_invite:", response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
        handleCloseInvitePopup();
      } else if(response.data.status === 501){
        setInviteSentMessage(response.data.message);
      } else if(response.data.status === 401){
        alert(response.data.message);
      }
    })
    .catch(err=>{
      console.log("error is",err);
      console.log(err.response.data);
      if(err.response.data.status === 501){
        setInviteSentMessage(err.response.data.message);
      } else if(err.response.data.status === 401){
        alert(err.response.data.message);
      }
    });
  }
  //send invite to customer - end

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    //let jwt = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJTQXEzRG5SSUwzekxTS1oxeVJuR0xwejJWSy1kajk5TmxSRUgwRENvZTJRIn0.eyJleHAiOjE2NjQxODQwNjgsImlhdCI6MTY2NDE4Mzc2OCwiYXV0aF90aW1lIjoxNjY0MTgxMjg0LCJqdGkiOiIxOGZjYzU3Yy1lNjAyLTQ4YjYtOTQ3OC01MmQ1ZmVhMzJjZjAiLCJpc3MiOiJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2My5hbWJlcm9vbi5jb20vYXV0aC9yZWFsbXMvYXBwcyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1YjIyMTFhMy1mYWM3LTRmYjgtOWI5NC0zMGY1YjU0ODU3MzciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb25zb2xlLXVpIiwibm9uY2UiOiIzNmE1M2FlOS1hMzc4LTRkZDItODA2Yi02NDhlNmIyY2I4YTQiLCJzZXNzaW9uX3N0YXRlIjoiZTMyODRhZDgtZmUzYS00ZjIyLWJjZWQtNTMwZGUwMTI2NTdlIiwiYWNyIjoiMCIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2Mi5hbWJlcm9vbi5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWFwcHMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiZTMyODRhZDgtZmUzYS00ZjIyLWJjZWQtNTMwZGUwMTI2NTdlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiR2VyYXJkIEFudG9ueSIsInByZWZlcnJlZF91c2VybmFtZSI6ImdlcmFyZC5hbnRvbnlAYW1iZXJvb24uY29tIiwiZ2l2ZW5fbmFtZSI6IkdlcmFyZCIsImZhbWlseV9uYW1lIjoiQW50b255IiwiZW1haWwiOiJnZXJhcmQuYW50b255QGFtYmVyb29uLmNvbSJ9.NAUkdgIBEFJeT8cyHzccvB7PirPaD-iPsH5R3XlwZ5L9yAigOm7q03jlEKQlSPbpM5q4ogW_aEwfJt6XF5mIBPouf-2QVWwW82Iqa8X46jaRq0LhJCjdEYuUPaofBXonCaJqyjtiut4V8ZqNw3C_b2QvrG4sCZld3MQpyixRuqLOVSS1g9pIzJLVi7Kxon2EjY7XoBUlqlIIwa0i7vw93zQPoDYK0BLxt2S31LWqWsGmz8x2-cAePtHSq71McXnqLNojeEgQdaAT6XPFfteKMep5yPgrIc5f2Xpvnl49Nj1EoYC6fOUtquEj-qM2BcZAMN4vehlqGKbMmUMI6CRDsg";
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setMToken(response.data.token);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }

    setShowApplications('');
    setTimeout(() => {
      setShowApplications('all');
    }, 100);
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      /* check_user_privilege - access_request_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'access_request_list'})
      .then(response=>{
        console.log("access_request_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 401){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - create_invite */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("check_user_privilege response:", response);
        if(response.data.is_privileged === 0){
          setSendInviteDisabled(true);
        } else if(response.data.is_privileged === 1){
          setSendInviteDisabled(false);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    if(userPrivileged){
      getAppCount();
    }
  }, [mToken, userPrivileged]);

  const getAppCount = () => {
    //if(mToken !== null && mToken !== undefined && userPrivileged){
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken);
      /* Application Counts */
      axios.post(serverConfig.api_base_url + "get_access_request_count",{token:mToken})
      .then(response=>{
        console.log("get_access_request_count response: ", response);
        if (response.data.status === 200) {
          setApplicationCount(JSON.parse(response.data.items));
        } else if(response.data.status === 401){
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));
    }
  }

  const refreshAppList =(e) => {
    //e.preventDefault();
    axios.post(serverConfig.api_base_url + "get_access_request_list",{filter_check:'all', token:mToken})
    .then(response=>{
      console.log("get_access_request_list response:", response);
      console.log("get_access_request_list response:", response.data.status);
      if(response.data.status === 200){
        setShowApplications('');
        setTimeout(() => {
          setShowApplications('all');
        }, 100);
      } else if(response.data.status === 401){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <div className='page-heading'>
            <h1>Employee Retention Tax Credit Applications</h1>
          </div>
          <section>
            <Row className='application-stats'>
              {/* {props.isUserPrivileged ? (<> */}
                <Col>
                  <div className='inner' onClick={() => setShowApplications('all')}>
                    <h4>Total Applications</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'total' in applicationCount ? applicationCount.total : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/application-icon.png')} alt="Application icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('pending')}>
                    <h4>Pending Approval</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'pending' in applicationCount ? applicationCount.pending : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/pending-application-icon.png')} alt="Pending application icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('in_process')}>
                    <h4>In Process</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'in_process' in applicationCount ? applicationCount.in_process : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/inprocess-application-icon.png')} alt="In process Applications icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('completed')}>
                    <h4>Completed</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'completed' in applicationCount ? applicationCount.completed : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/approved-application-icon.png')} alt="Approved Application icon" /></span>
                    </div>
                  </div>
                </Col>
            </Row>
          </section>
          <section className='mt-5 mb-5'>
            <Button variant="transparent" className='txt-color1' onClick={handleShowInvitePopup} disabled={sendInviteDisabled}>Send Invite to New User</Button>
            <Modal className='modal-small' show={showInvitePopup} onHide={handleCloseInvitePopup} backdrop="static"keyboard={false} centered>
              <Form onSubmit={handleSendInvite}>
                <Modal.Header closeButton>
                  <Modal.Title>New User Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Name" name="name" value={inputs.name || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Mobile No." name="mobile" value={inputs.mobile || ""} onChange={handleChange} required />
                  </Form.Group>
                  <p className='txt-red mb-0'>{inviteSentMessage ? inviteSentMessage : <>&nbsp;</>}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="transparent" onClick={handleCloseInvitePopup}>Cancel</Button>
                  <Button variant="primary" type="submit" className='ml-3'><img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" className='mr-2' />Send Invite</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </section>
          <section>
            <div className='datatable datatable-app-list'>
              <h2>Total Applications <button className='ml-3' onClick={refreshAppList}><i className="fas fa-sync"></i></button></h2>
              <AppDataGrid show={showApplications} refreshAppList={refreshAppList} getAppCount={getAppCount} token={mToken} />
            </div>
          </section>
        </Container>
      </main>
    </>
  );
}

export default Applications;
