import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserService from '../services/UserService';

const Header = () => {

  //console.log('JWT Token ',UserService.getToken());

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  return (
    <header>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/" className='logo'><img src={require('./../assets/images/logo.png')} alt="Amberoon Logo" /></Link>
          <div className='header-right'>
            <span className='me-2'>Hello {UserService.getWelcomeName()}!</span>
            <span className='user-icon'></span>
            <span className='login-logout'>
              <button onClick={handleLogout}></button>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
