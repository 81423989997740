import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const PPPLoans = ({ nextStep, prevStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});
  
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ppp1Name, setPPP1Name] = useState('');
  const [ppp2Name, setPPP2Name] = useState('');

  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='pdf' || extension=='csv' || extension=='jpg' || extension=='png' || extension=='xml' || extension=='json' || extension=='xbrl' || extension=='txt' || extension=='prn'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      //console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        if(name === 'PPP1'){
          setPPP1Name(filename);
        } else if(name === 'PPP2'){
          setPPP2Name(filename);
        }
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          //console.log(fileData);
          //newData[name]=fileData;
          //newData[name+'_filename']=filename;
          axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
            } else if(response.data.status === 401){
              getToken();
              alert('Inactive session. Please upload the file again.');
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only pdf, csv, jpg, png, xml, json, xbrl, txt, prn files are allowed");
      event.target.value = null;
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        setPPP1Name(items.PPP1);
        setPPP2Name(items.PPP2);
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="position-relative">
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h1>Please upload Loan Forgiveness Application Form 3508 for all PPP loans</h1>
          <h5>PPP 1 {mandatoryEntities.includes("PPP1") && <span className='txt-red'>*</span>}</h5>
          <div className="position-relative mt-4 mb-5">
            <Form.Label htmlFor="PPP1" className='upload'>{ppp1Name ? ppp1Name : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u></>}</Form.Label>
            <Form.Control id="PPP1" name="PPP1" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
          </div>
          <h5>PPP 2 {mandatoryEntities.includes("PPP2") && <span className='txt-red'>*</span>}</h5>
          <div className="position-relative mt-4">
            <Form.Label htmlFor="PPP2" className='upload'>{ppp2Name ? ppp2Name : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u></>}</Form.Label>
            <Form.Control id="PPP2" name="PPP2" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
          </div>
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default PPPLoans;
