import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const Form941 = ({ prevStep, nextStep, token, entityName, getToken }) => {

  const[getFormdata, setGetFormdata] = useState({});

  const [fileInputs, setFileInputs] = useState({});
  const [mandatoryEntities, setMandatoryEntities] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='pdf' || extension=='csv' || extension=='jpg' || extension=='png' || extension=='xml' || extension=='json' || extension=='xbrl' || extension=='txt' || extension=='prn'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      //console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          //console.log(fileData);
          setFileInputs({
            ...fileInputs,
            [name]:filename,
          });
          axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
            } else if(response.data.status === 401){
              getToken();
              alert('Inactive session. Please upload the file again.');
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only pdf, csv, jpg, png, xml, json, xbrl, txt, prn files are allowed");
      event.target.value = null;
    }
  }

	useEffect(() => {
		sessionStorage.getItem('token', token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_data",formdata)
    .then(response=>{
      console.log('get_data response', response);
      console.log(response.data.items);
      let res = JSON.parse(response.data.items);
      console.log(res);
      if(res !== null){
        let items = Object.assign({}, ...res);
        console.log(items);
        /* setFileInputs({
          Form9412020Q1File:items.Form9412020Q1File,
          Form9412020Q2File:items.Form9412020Q2File,
          Form9412020Q3File:items.Form9412020Q3File,
          Form9412020Q4File:items.Form9412020Q4File,
          Form9412021Q1File:items.Form9412021Q1File,
          Form9412021Q2File:items.Form9412021Q2File,
          Form9412021Q3File:items.Form9412021Q3File,
          Form9412021Q4File:items.Form9412021Q4File,
        }); */
        setGetFormdata(items);
      }

      let mandatory_entities = JSON.parse(response.data.mandatory_entities);
      console.log(mandatory_entities);
      if(mandatory_entities !== null){
        setMandatoryEntities(mandatory_entities);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="position-relative">
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <h1>Form 941</h1>
          <h5 className="mb-4">Use the table below to upload your tax filing documents:</h5>

          <Table striped borderless>
            <thead>
              <tr>
                <th width="10%">Calculation Year</th>
                <th width="22%">Quarter 1 (Jan - Mar)</th>
                <th width="22%">Quarter 2 (Apr - Jun)</th>
                <th width="22%">Quarter 3 (Jul - Sep)</th>
                <th width="22%">Quarter 4 (Oct - Dec)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>2020</th>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412020Q1File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412020Q1File") && '*'}</span>
                    {getFormdata.Form9412020Q1File ? (fileInputs.Form9412020Q1File || getFormdata.Form9412020Q1File) : (fileInputs.Form9412020Q1File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412020Q1File" name="Form9412020Q1File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412020Q2File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412020Q2File") && '*'}</span>
                    {getFormdata.Form9412020Q2File ? (fileInputs.Form9412020Q2File || getFormdata.Form9412020Q2File) : (fileInputs.Form9412020Q2File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412020Q2File" name="Form9412020Q2File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412020Q3File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412020Q3File") && '*'}</span>
                    {getFormdata.Form9412020Q3File ? (fileInputs.Form9412020Q3File || getFormdata.Form9412020Q3File) : (fileInputs.Form9412020Q3File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412020Q3File" name="Form9412020Q3File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412020Q4File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412020Q4File") && '*'}</span>
                    {getFormdata.Form9412020Q4File ? (fileInputs.Form9412020Q4File || getFormdata.Form9412020Q4File) : (fileInputs.Form9412020Q4File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412020Q4File" name="Form9412020Q4File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>2021</th>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412021Q1File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412021Q1File") && '*'}</span>
                    {getFormdata.Form9412021Q1File ? (fileInputs.Form9412021Q1File || getFormdata.Form9412021Q1File) : (fileInputs.Form9412021Q1File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412021Q1File" name="Form9412021Q1File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412021Q2File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412021Q2File") && '*'}</span>
                    {getFormdata.Form9412021Q2File ? (fileInputs.Form9412021Q2File || getFormdata.Form9412021Q2File) : (fileInputs.Form9412021Q2File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412021Q2File" name="Form9412021Q2File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412021Q3File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412021Q3File") && '*'}</span>
                    {getFormdata.Form9412021Q3File ? (fileInputs.Form9412021Q3File || getFormdata.Form9412021Q3File) : (fileInputs.Form9412021Q3File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412021Q3File" name="Form9412021Q3File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
                <td>
                  <div className="position-relative">
                  <Form.Label htmlFor="Form9412021Q4File" className='upload upload-default'>
                    <span className="txt-upload">Upload {mandatoryEntities.includes("Form9412021Q4File") && '*'}</span>
                    {getFormdata.Form9412021Q4File ? (fileInputs.Form9412021Q4File || getFormdata.Form9412021Q4File) : (fileInputs.Form9412021Q4File || <span className="txt-gray">No file chosen</span>)}
                  </Form.Label>
                  <Form.Control id="Form9412021Q4File" name="Form9412021Q4File" type="file" accept=".pdf, .csv, .jpg, .png, .xml, .json, .xbrl, .txt, .prn" onChange={handleUpload} />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          
          <Row className="btn-container">
            <Col xs="auto">
              <Button variant="transparent" onClick={prevStep}>Previous</Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Next</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Form941;
